/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        //project popup
        $( ".popupdive" ).click(function(){

          var preload = '<div class="d-flex justify-content-center"><div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div></div>';
          $("#prodcutpoup .modal-content").html(preload);
          var p_id =  $(this).attr('p_id');
          var  method ="post";
          var  url ="projectdata";
           $.ajax({
                  type: method,
                  url: ajaxurl,
                  data: {
                    'action':url,
                    'p_id': p_id

                  },
                  success:function(data) {
                    $("#prodcutpoup .modal-content").html(data);
                  },
                  error: function(errorThrown){
                      console.log(errorThrown);
                  }
                });

        }); 
        // JavaScript to be fired on all pages
          $('.regiter-please').on('click', function(e){
              e.preventDefault();
              $(this).hide();
              $('.login-screen').toggleClass('active');
              $('.register-screen').toggleClass('active');
            });

           var navHeigh = $('.new-main-nav').height();
    
             /* $('.wrapper').css({
                  'margin-top' : '-'+navHeigh+'px' 
              }); */

            $('.menu-icon').on('click', function(){
                $(this).toggleClass('opened-menu');

                $('.new-main-nav').toggleClass('show-nav');

                if($('.new-main-nav').hasClass('show-nav')){
                   /* $('.wrapper').css({
                        'margin-top' : '0px' 
                    }); */
                }else{
                  /*  $('.wrapper').css({
                        'margin-top' : '-'+navHeigh+'px' 
                    }); */
                }

            });

           $('.scroll').on("click", function(e) {

              e.preventDefault();

              var dest = $(this).attr("href");

              $("html, body").animate({

                'scrollTop':   $(dest).offset().top

              }, 2000);

            });

           
           
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
